.alertMain {
  border: 1px solid #cbd1dc;
  /* padding: 5px 28px 131px 6px; */
  margin-top: 11px;
  overflow: scroll;
  height: 280px;
}
.alertContainer {
  border-bottom: 1px solid #f2f2f2;
  padding: 6px 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.alertInfo-description {
  font-size: 12px;
  font-weight: 400;
  padding-top: 2px;
}
.alertInfo {
  flex-direction: column;
  display: flex;
}
