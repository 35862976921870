.modalHeader {
  height: 32px;
  background-color: #131b45;
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  align-items: center;
  border: 1px solid #131b45;
}
.modalHeader span {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.modalHeader img {
  width: 14px;
  height: 14px;
}
.modalMain {
  padding: 11px 14px;
}
.mainAddress {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.mainCard {
  display: flex;
  width: 433px;
  height: 78px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-top: 8px;
}
