.roomContainer {
  display: flex;
  padding-bottom: 6px;
  justify-content: center;
  align-items: center;
}

.room {
  border-radius: 8px;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 5px;
}

.room-header-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1px;
  width: 100%;
}
.alerts {
  display: flex;
  background-color: #ff5050;
  border-radius: 4px;
  flex-shrink: 0;
  width: 81px;
  height: 21px;
  justify-content: center;
  align-items: center;
}
.alerts span {
  padding: 2.5px 0px;
  font-size: 14px;
  font-weight: 500;
}
.room .addressText {
  font-size: 13px;
  font-weight: 700;
  color: #291345;
  flex: 1;
}

.addressText {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.roomStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.status-logo {
  width: 48px;
  height: 48px;
}
.roomUserStatus {
  font-size: 22px;
  font-weight: 500;
  color: #291345;
  margin-right: 4px;
}

.room.empty {
  background-color: #acddd1;
  border: 1px solid #73c3b9;
}

.room.sleep {
  background-color: #bcc9f8;
  border: 1px solid #96aaf0;
}

.room.active {
  background-color: #f8d48e;
  border: 1px solid #ecbd80;
}

.roomSpan {
  font-size: 14px;
  height: 16px;
  font-weight: 500;
  color: #291345;
  display: flex;
}
.roomStatus .logo {
  height: 26px;
}
.void_count {
  color: #131b454d;
}

.room_status_container {
  padding: 0px 14px;
}
