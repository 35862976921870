/* Pagination.css */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  background-color: #f0f4f8;
  padding: 10px;
  margin-bottom: 30px;
}

.pagination-arrow {
  width: 20px;
  height: 20px;
  background-color: "transparent";
  border: 1px solid #d8e2eb;
  color: #959ab8;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.pagination-arrow:disabled {
  color: #dfe4ef;
}

.pagination-number {
  background-color: transparent;
  border: none;
  color: #9099a9;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-number.active {
  color: #007bff;
  font-weight: bold;
}

.pagination-number:hover {
  color: #007bff;
}
