.loginMain {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  flex: 1;
  background-image: url("../assets//login.svg");
  align-items: center;
  justify-content: center;
}
.loginBox {
  min-height: 220px;
  background-color: #fff;
  /* height: 300px; */
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  border-radius: 10px;
}
.select {
  border: 1px solid #596b8b;
  width: 140px;
  height: 24px;
  padding: 0;
  margin-top: 35px;
}
.input {
  border: 1px solid #596b8b;
  width: 138px;
  height: 24px;
  padding: 0;
  margin-top: 8px;
}
.button {
  margin-top: 14px;

  width: 140px;
  height: 32px;
  color: #fff;
  border: none;
}
