.modalLogo {
  width: 48px;
  height: 48px;
}
.mainCard.active {
  background-color: #f8d48e;
}

.mainCard.empty {
  background-color: #acddd1;
}

.mainCard.sleep {
  background-color: #bcc9f8;
}
.modalUserStatus {
  font-size: 25px;
  font-weight: 500;
}
.modalUserStatus.active {
  color: #ff7a00;
}

.modalUserStatus.empty {
  color: #107d95;
}

.modalUserStatus.sleep {
  color: #4534ac;
}
.modalStatus {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.modalStatus .title {
  font-size: 14px;
  color: #000;
}
.statusInfo {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between;
  width: 357px; */
}
.statusInfoDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.infoSpan {
  display: flex;
  align-items: end;
  justify-content: center;
}
.modalIcon {
  width: 48px;
  height: 48px;
  padding: 0 9px;
}
.modalIcon-number {
  font-size: 40px;
  font-weight: 700;
}
.modalIcon-title {
  font-size: 20px;
  font-weight: 500;
}

.modal-button {
  margin-top: 20px;
  display: flex;
  padding: 17px 17px;
  border: 1px solid #aaa;
  border-radius: 6px;
  width: 432px;
  justify-content: center;
  border-width: 1px;
}

.modal-button-title {
  font-size: 15px;
  font-weight: 500;
}
.modal-alert-info {
  margin-top: 20px;
  display: flex;
  background-color: #ff5050;
  padding: 6px 10px;
  border-radius: 6px;
  width: 432px;
  justify-content: space-between;
}
.modal-alert-info-left {
  display: flex;
  flex-direction: column;
}

.modal-alert-text {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}

.modal-alert-description {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  padding-top: 2px;
}

.modal-alert-info-right {
  display: flex;
  align-items: flex-start;
}
.modal-alert-date {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
