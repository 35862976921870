* {
  box-sizing: border-box;
}

.layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 1180px;
  height: 100vh;
  overflow-x: hidden;
}

.main {
  background-color: #f0f5fb;
  padding-top: 21px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background-color: #fff;
  height: 80px;
  padding-left: 20px;
  border-bottom: 1px solid #007af5;
  min-height: 80px;
  padding-right: 30px;
}

.header .header-left {
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
}

.header-left .logo {
  width: 60px;
  height: 40px;
}

.header-left .location-title {
  font-size: 18px;
  font-weight: 700;
  color: #0b3a8a;
  align-items: flex-end;
}

.header .header-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.header-center .main-title {
  font-size: 24px;
  font-weight: 700;
  color: #0b3a8a;
  white-space: nowrap;
}

.header .header-right {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.header-right .alert-info {
  display: flex;
  background-color: #ff5050;
  padding: 8px 16px;
  border-radius: 6px;
  width: 432px;
  justify-content: space-between;
}

.alert-info-left {
  display: flex;
  flex-direction: column;
}

.alert-text {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}

.alert-description {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.alert-info-right {
  display: flex;
  align-items: center;
}
.alert-date {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.right {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  align-items: flex-end;
}

.header-right .date-time {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  padding: 8px;
}

.date-time .date {
  font-size: 15px;
  font-weight: 400;
  color: #131b45;
}

.date-time .time {
  font-size: 16px;
  font-weight: 700;
  color: #131b45;
  text-align: end;
  padding-left: 4px;
}

.dashboard_header {
  align-items: center;
  justify-content: center;
  display: flex;
}
.controls {
  width: 1070px;
  display: flex;
  justify-content: space-between;
}
.roomTitle {
  font-size: 18px;
  font-weight: 500;
}
.itemsPerPage {
  font-size: 16px;
  font-weight: 500;
  margin-right: 9px;
}
.itemsPerPage-number {
  width: 60px;
  height: 24px;
  border: 1px solid #596b8b;
}
.dashboard {
  display: grid;
  grid-template-columns: 184px 184px 184px 184px 184px 184px;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #f0f5fb; */
  flex-direction: row;
  margin-top: 16px;
  gap: 6px;
}
.Logout {
  background-color: #007af5;
  border: none;
  height: 22px;
  width: 63px;
  margin-left: 8px;
}
.Logout span {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
